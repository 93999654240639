import omit from 'lodash/omit';
import queryString from 'query-string';

import { PatientViewableProviderLicenseType } from '@headway/api/models/PatientViewableProviderLicenseType';
import { ProviderSearchFilters } from '@headway/api/models/ProviderSearchFilters';
import { PatientViewableProviderLicenseTypeDisplayNamesForSearch } from '@headway/shared/utils/marketLicenseTypes';

import { NextRouter } from '../hooks/useRouter';

/**
 * Generates a query string for pagination, preserving existing query parameters
 * @returns A query string with updated pagination
 */
export const getUpdatedPaginationPath = (
  router: NextRouter,
  pageNumber: number
): string => {
  const path = router.asPath.split(/\?|#/, 1)[0];

  // If page is 1, we omit it from the query params as it's the default
  const newQuery =
    pageNumber === 1
      ? omit(router.query, ['currentPage', 'state'])
      : {
          ...omit(router.query, 'state'),
          currentPage: pageNumber,
        };

  const queryStr = queryString.stringify(newQuery);
  return queryStr ? `${path}?${queryStr}` : path;
};

/**
 * Extracts only valid keys for license type query params. This is necessary
 * because the checkbox tree helix component we are using for the credential
 * filter includes both the higher & lower level labels when selected
 */
export const getValidProviderLicenseTypes = (
  currentFilters: ProviderSearchFilters
): PatientViewableProviderLicenseType[] => {
  return (
    currentFilters.providerLicenseType?.filter(
      (licenseType) =>
        licenseType in PatientViewableProviderLicenseTypeDisplayNamesForSearch
    ) || []
  );
};
