import { UserRead } from '@headway/api/models/UserRead';

export const HEALTHCARE_REFERRERS_CACHE_KEY = 'referrers';
export const HEALTHCARE_REFERRER_ORGANIZATIONS_CACHE_KEY =
  'referrer-organizations';
export const PATIENT_VISIBLE_MODALITIES_CACHE_KEY =
  'patient-visible-modalities';
export const PATIENT_VISIBLE_SPECIALTIES_CACHE_KEY =
  'patient-visible-specialties';
export const PROVIDER_BOOKING_PREFERENCE_CACHE_KEY =
  'provider-booking-preference';
export const PROVIDER_HIGHLIGHTS_CACHE_KEY = 'provider-highlights';
export const PROVIDERS_NEXT_AVAILABILITIES_CACHE_KEY =
  'providers-next-availabilities';
export const VERIFY_CLAIM_READINESS_QUERY_KEY = 'claim-readiness';
export const PATIENT_ADDRESSES_QUERY_KEY = 'patient-addresses';
export const FRONT_END_CARRIER_QUERY_KEY = 'front-end-carrier';
export const GET_ELIGIBILITY_LOOKUP_CACHE_KEY = 'get-eligibility-lookup';
export const PROVIDERS_CACHE_KEY = 'providers';
export const PROVIDER_EVENTS_CACHE_KEY = 'provider-events';
export const PROVIDER_EVENTS_ELIGIBILITY_LOOKUPS_CACHE_KEY =
  'provider-events-lookups';

export const GET_PROVIDER_PRICE_CACHE_KEY = 'get-provider-price';
export const FIND_UPCOMING_EVENTS_CACHE_KEY = 'find-upcoming-events';
export const FIND_PAST_EVENTS_CACHE_KEY = 'find-past-events';
export const GET_USER_INSURANCES_CACHE_KEY = 'user-insurances';

export const GET_USER_INSURANCE_AUTHORIZATIONS_CACHE_KEY =
  'user-insurance-authorizations';

export const GEOLOCATION_LADDER_CACHE_KEY = 'geolocation-ladder';

export function getProviderPriceCacheKey(providerId: number, user: UserRead) {
  return [
    GET_PROVIDER_PRICE_CACHE_KEY,
    {
      providerId,
      patientId: user.id,
      // NOTE: This is to get the query to re-fetch when the user changes insurance.
      // This is because the price is dependent on the user's insurance but that relationship
      // is not reflected in the API inputs.
      insuranceId: user.activeUserInsuranceId,
    },
  ];
}

export function findUpcomingEventsCacheKey(user: UserRead) {
  return [FIND_UPCOMING_EVENTS_CACHE_KEY, user.id];
}

export function findPastEventsCacheKey(user: UserRead) {
  return [FIND_PAST_EVENTS_CACHE_KEY, user.id];
}

const CARRIERS_AND_PROVIDER_PRICE_CACHE_KEY = 'carriers-and-provider-price';

export const getCarriersAndProviderPriceCacheKey = ({
  providerId,
  patientUserId,
  activeUserInsuranceId,
}: {
  providerId: number;
  patientUserId: number;
  activeUserInsuranceId?: number;
}) => {
  return [
    CARRIERS_AND_PROVIDER_PRICE_CACHE_KEY,
    providerId,
    patientUserId,
    // NOTE: This is to get the query to re-fetch when the user changes insurance.
    // This is because the price is dependent on the user's insurance but that relationship
    // is not reflected in the API inputs.
    activeUserInsuranceId,
  ].filter((val) => val !== undefined);
};
