/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum ProviderSearchIndexLocationStrategy {
    NEARBY_FIRST_AND_DO_NOT_REQUIRE_IN_GEOFENCE = 'NEARBY_FIRST_AND_DO_NOT_REQUIRE_IN_GEOFENCE',
    NEARBY_FIRST_AND_REQUIRE_IN_GEOFENCE = 'NEARBY_FIRST_AND_REQUIRE_IN_GEOFENCE'
}


export const ProviderSearchIndexLocationStrategyMetadata: EnumMetadata<ProviderSearchIndexLocationStrategy> = {
    name: 'ProviderSearchIndexLocationStrategy',
    values: {
            NEARBY_FIRST_AND_DO_NOT_REQUIRE_IN_GEOFENCE: 'NEARBY_FIRST_AND_DO_NOT_REQUIRE_IN_GEOFENCE',
            NEARBY_FIRST_AND_REQUIRE_IN_GEOFENCE: 'NEARBY_FIRST_AND_REQUIRE_IN_GEOFENCE'
    }
}
