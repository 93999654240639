/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { GeolocationRead } from '../models/GeolocationRead';
import { GeolocationType } from '../models/GeolocationType';
import { HTTPValidationError } from '../models/HTTPValidationError';
import { PreciseGeolocation } from '../models/PreciseGeolocation';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class GeolocationApiResource {

    /**
     * Get Geolocation Ladder
     * Returns a list of Geolocation objects, starting with the state, and ending with the tightest-fit location given the input values. Ex. [California, Los Angeles County, Los Angeles, Silverlake]  :param state: The slug of the location\&#39;s state. :param extra_location_descriptor: Any further identifying information, such as city name or neighborhood name. :param lat: The latitude coordinate of the location. :param lng: The longitude coordinate of the location. :param location_type: The GeolocationType of the location. :return: List of Geolocations.
     * @param state 
     * @param extraLocationDescriptor 
     * @param preciseGeolocation 
     
     */
    public getGeolocationLadder(body?: PreciseGeolocation, query?: { state: string, extra_location_descriptor?: string }, axiosConfig?: AxiosRequestConfig): Promise<Array<GeolocationRead>> {
        const apiRoute = '/geolocation/ladder';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Sublocations Of Interest
     * Depending on location_type, returns the top Google maps results for the top neighborhoods in the input location, or the top cities.  :param location_name: The name of the location, i.e. New York City, or California. :param location_type: The GeolocationType of the location. :return: List of Geolocations.
     * @param locationName 
     * @param locationType 
     
     */
    public getSublocationsOfInterest(query?: { location_name: string, location_type: GeolocationType }, axiosConfig?: AxiosRequestConfig): Promise<Array<GeolocationRead>> {
        const apiRoute = '/geolocation/sublocations_of_interest';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const GeolocationApi = new GeolocationApiResource();
