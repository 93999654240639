import moment from 'moment';

import { ProviderRead } from '@headway/api/models/ProviderRead';

export function getVisibleAvailabilityStartDate(
  from: Date,
  provider: ProviderRead
) {
  return moment(from).add(provider.soonestBookingCutoffHours, 'hours').toDate();
}

export function getVisibleAvailabilityEndDate(from: Date) {
  return moment(from).add(14, 'days').endOf('day').toDate();
}
