import { FocusableProvider } from '@react-aria/focus';
import { PlacementAxis } from '@react-types/overlays';
import React, { HTMLAttributes, RefObject, useContext, useRef } from 'react';
import {
  mergeProps,
  OverlayContainer,
  useOverlayPosition,
  useTooltip,
  useTooltipTrigger,
} from 'react-aria';
import { TooltipTriggerState, useTooltipTriggerState } from 'react-stately';

interface TooltipProps {
  isOpen?: boolean;

  children: React.ReactNode;
}

function Tooltip(props: TooltipProps) {
  let {
    ref: overlayRef,
    arrowProps,
    state,
    style,
    placement,
    ...tooltipProviderProps
  } = useContext(TooltipContext);
  let defaultRef = useRef(null);

  overlayRef = overlayRef || defaultRef;
  props = mergeProps(props, tooltipProviderProps);

  let { tooltipProps } = useTooltip(props, state);

  // Sync ref with overlayRef from context.
  // useImperativeHandle(ref, () => createDOMRef(overlayRef));

  return (
    <div
      {...tooltipProps}
      className="hlx-tooltip"
      data-placement={placement}
      style={style}
      ref={overlayRef}
    >
      {props.children && (
        <span className="hlx-tooltip-label">{props.children}</span>
      )}
      <span {...arrowProps} className="hlx-tooltip-arrow" />
    </div>
  );
}

interface TooltipContextProps {
  state?: TooltipTriggerState;
  ref?: RefObject<HTMLDivElement>;
  placement: PlacementAxis;
  arrowProps?: HTMLAttributes<HTMLElement>;
  style?: React.CSSProperties;
}

export const TooltipContext = React.createContext<TooltipContextProps>(
  {} as TooltipContextProps
);

interface TooltipTriggerProps {
  children: [React.ReactElement, React.ReactElement];
  disabled?: boolean;
  placement?: 'top' | 'right' | 'bottom' | 'left';
}

function TooltipTrigger(props: TooltipTriggerProps) {
  let { children, disabled: isDisabled } = props;

  let [trigger, tooltip] = React.Children.toArray(children);

  let state = useTooltipTriggerState({
    ...props,
    delay: 700,
    isDisabled,
  });

  let tooltipTriggerRef = useRef<HTMLElement>(null);
  let overlayRef = useRef<HTMLDivElement>(null);

  let { triggerProps, tooltipProps } = useTooltipTrigger(
    {
      isDisabled,
    },
    state,
    tooltipTriggerRef
  );

  let { overlayProps, arrowProps, placement, updatePosition } =
    useOverlayPosition({
      placement: props.placement ?? 'bottom',
      targetRef: tooltipTriggerRef,
      overlayRef,
      offset: 4,
      crossOffset: 0,
      isOpen: state.isOpen,
      shouldFlip: true,
    });

  React.useEffect(() => {
    if (state.isOpen) {
      requestAnimationFrame(() => {
        updatePosition();
      });
    }
  }, [state.isOpen, updatePosition]);

  return (
    <FocusableProvider {...triggerProps}>
      {React.cloneElement(trigger as React.ReactElement, {
        ref: tooltipTriggerRef,
      })}
      <TooltipContext.Provider
        value={{
          state,
          placement: placement ?? 'bottom',
          ref: overlayRef,
          arrowProps,
          ...tooltipProps,
          style: overlayProps.style,
        }}
      >
        {state.isOpen && (
          <OverlayContainer>
            <div className="hlx-tooltip-overlay">{tooltip}</div>
          </OverlayContainer>
        )}
      </TooltipContext.Provider>
    </FocusableProvider>
  );
}

export { Tooltip, TooltipTrigger };
