/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum PatientViewableProviderLicenseType {
    CLINICAL_SOCIAL_WORKER = 'clinical_social_worker',
    MENTAL_HEALTH_COUNSELOR = 'mental_health_counselor',
    LICENSED_PROFESSIONAL_COUNSELOR = 'licensed_professional_counselor',
    MARRIAGE_AND_FAMILY_THERAPIST = 'marriage_and_family_therapist',
    SUBSTANCE_USE_COUNSELOR = 'substance_use_counselor',
    DO = 'do',
    MD = 'md',
    PSYCHIATRIC_MENTAL_HEALTH_NURSE_PRACTITIONER = 'psychiatric_mental_health_nurse_practitioner',
    PSYCHOLOGIST = 'psychologist'
}


export const PatientViewableProviderLicenseTypeMetadata: EnumMetadata<PatientViewableProviderLicenseType> = {
    name: 'PatientViewableProviderLicenseType',
    values: {
            CLINICAL_SOCIAL_WORKER: 'clinical_social_worker',
            MENTAL_HEALTH_COUNSELOR: 'mental_health_counselor',
            LICENSED_PROFESSIONAL_COUNSELOR: 'licensed_professional_counselor',
            MARRIAGE_AND_FAMILY_THERAPIST: 'marriage_and_family_therapist',
            SUBSTANCE_USE_COUNSELOR: 'substance_use_counselor',
            DO: 'do',
            MD: 'md',
            PSYCHIATRIC_MENTAL_HEALTH_NURSE_PRACTITIONER: 'psychiatric_mental_health_nurse_practitioner',
            PSYCHOLOGIST: 'psychologist'
    }
}
