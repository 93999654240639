import {
  AriaTextFieldProps,
  useFormattedTextField,
} from '@react-aria/textfield';
import { useId, useSlotId } from '@react-aria/utils';
import { useControlledState } from '@react-stately/utils';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { FocusRing, mergeProps, useFocus } from 'react-aria';

import { FieldError, FieldRoot } from './fields';
import { formatEIN, formatPhoneNumber, formatSSN } from './formatters';
import { Validator } from './forms';
import { useAssertFormParentEffect } from './useAssertFormParentEffect';
import { useFieldValidity } from './useFieldValidity';
import { FormInputProps, useFormInput } from './useFormInput';

type MaskedTextFieldProps = {
  id?: string;
  optionalityText?: React.ReactNode;
  /**
   * The mask to use for the input.  This is a string of characters that
   * represent the mask.  Use _ to represent a character that can be typed
   * and any other character to represent a fixed character.
   */
  mask: string;
  /**
   * A function that returns the formatted value for the input.  This is
   * called whenever the input value changes, onBlur, and onMount.
   */
  format: (value: string) => string;
  /**
   * Whether the input should be obscured with bullets when the user is
   * not focused on the input.
   **/
  obscure?: string;

  constraints?: Validator<string>[];
} & FormInputProps<string> &
  Pick<AriaTextFieldProps, 'inputMode' | 'type'>;

const EMPTY_SPACE = '\u2002';

const MaskedTextField = (props: MaskedTextFieldProps) => {
  const controlRef = React.useRef<HTMLInputElement>(null);
  const rootRef = React.useRef<HTMLDivElement>(null);
  const { ariaProps, hoverProps, focusProps, isFocused, isFocusVisible } =
    useFormInput({
      ...props,
      isTextInput: true,
    });

  const { fieldRootProps, ...validationProps } = useFieldValidity({
    ref: rootRef,
    constraints: props.constraints,
    validation: props.validation,
    onCheckValidity: () => {
      if (isInvalid) {
        return false;
      }

      return true;
    },
    focus() {
      controlRef.current?.focus();
    },
  });

  let inputId = useId(props.id);

  let [inputValue, setInputValue] = React.useState(() =>
    props.format(props.value ?? '')
  );

  let state = {
    inputValue,
    validate(value: string) {
      const cleaned = value.replace(/\D/g, '');
      const allowed = props.mask.match(/_/g)?.length ?? 0;
      return cleaned.length <= allowed;
    },
    setInputValue,
  };

  useEffect(() => {
    setInputValue(props.format(props.value ?? ''));
  }, [props.value]);

  const shouldObscure =
    props.obscure && !isFocused && inputValue.length === props.mask.length;
  let displayValue = inputValue;

  if (shouldObscure) {
    displayValue = props.obscure!.replace(/_/g, (_, offset: number) => {
      return inputValue.charAt(offset);
    });
  } else if (inputValue.trim().length === 0) {
    // create a string of spaces to fill the space up to the first non-mask character. This way the
    // user's cursor will be positioned at the first non-mask character when they start typing.
    const firstNonMaskChar = props.mask.indexOf('_');
    displayValue = EMPTY_SPACE.repeat(firstNonMaskChar);
  }

  let {
    labelProps,
    inputProps,
    descriptionProps,
    errorMessageProps,
    isInvalid,
    validationErrors,
  } = useFormattedTextField(
    mergeProps(ariaProps, validationProps, {
      autoFocus: props.autoFocus,
      value: displayValue,
      defaultValue: undefined, // defaultValue already used to populate state.inputValue, unneeded here
      autoComplete: 'off',
      'aria-label': props['aria-label'] || undefined,
      'aria-labelledby': props['aria-labelledby'] || undefined,
      id: inputId,
      type: props.type ?? 'text',
      inputMode: props.inputMode ?? 'text',
      onFocus(e) {
        if (props.onFocus) {
          props.onFocus(e);
        }
      },
      onBlur() {
        const formatted = props.format(inputValue);
        state.setInputValue(formatted);
      },
      onChange(value) {
        const formatted = props.format(value);
        state.setInputValue(formatted);

        if (props.onChange) {
          props.onChange(formatted);
        }
      },
    } as AriaTextFieldProps),
    state,
    controlRef
  );

  const optionalityId = useSlotId([Boolean(props.optionalityText)]);

  useAssertFormParentEffect(controlRef, 'MaskedTextField');

  return (
    <FieldRoot
      ref={rootRef}
      className="hlx-masked-text-field-root"
      isInvalid={isInvalid}
      isDisabled={props.disabled}
      isReadonly={props.readonly}
      {...fieldRootProps}
    >
      <label className="hlx-masked-text-field-label" {...labelProps}>
        {props.label}
      </label>

      <div
        className={clsx('hlx-masked-text-field-control-group', {
          focused: isFocused,
          'focus-ring': isFocusVisible,
        })}
        {...mergeProps(hoverProps)}
      >
        {/* We show an absolutely positioned placeholder atop the input */}
        {!shouldObscure && isFocused && (
          <div aria-hidden="true" className="hlx-masked-text-field-placeholder">
            {props.mask.split(/([^_])/).map((segment, i) => {
              if (!segment.includes('_')) {
                return segment;
              }

              return (
                <span
                  className="hlx-masked-text-field-control-segment"
                  key={i}
                  style={{
                    display: 'inline-block',
                    minWidth: segment.length + 'ch',
                    visibility: 'hidden',
                  }}
                >
                  {inputValue.slice(i, i + segment.length)}
                </span>
              );
            })}
          </div>
        )}

        <input
          className="hlx-masked-text-field-control"
          ref={controlRef}
          {...mergeProps(
            (props.optionalityText
              ? { 'aria-describedby': optionalityId }
              : {}) as { 'aria-describedby'?: string },
            inputProps,
            focusProps
            // hoverProps
          )}
        />
      </div>

      {props.optionalityText && (
        <div
          id={optionalityId}
          className="hlx-masked-text-field-optionality-text"
        >
          {props.optionalityText}
        </div>
      )}
      {props.helpText && (
        <div className="hlx-masked-text-field-help-text" {...descriptionProps}>
          {props.helpText}
        </div>
      )}
      <FieldError
        className="hlx-masked-text-field-error"
        isInvalid={isInvalid}
        validationErrors={validationErrors}
        validation={props.validation}
        {...errorMessageProps}
      />
    </FieldRoot>
  );
};

type PhoneNumberFieldProps = Omit<
  MaskedTextFieldProps,
  'mask' | 'format' | 'inputMode'
>;

export function PhoneNumberField(props: PhoneNumberFieldProps) {
  return (
    <MaskedTextField
      {...props}
      mask="(___) ___-____"
      format={formatPhoneNumber}
      type="tel"
      inputMode="tel"
    />
  );
}

type SocialSecurityNumberFieldProps = Omit<
  MaskedTextFieldProps,
  'mask' | 'format' | 'inputMode' | 'obscure'
>;
export function SocialSecurityField(props: SocialSecurityNumberFieldProps) {
  return (
    <MaskedTextField
      {...props}
      mask="___-__-____"
      format={formatSSN}
      obscure="•••••••____"
    />
  );
}

type EinNumberFieldProps = Omit<
  MaskedTextFieldProps,
  'mask' | 'inputMode' | 'format'
>;
export function EinField(props: EinNumberFieldProps) {
  return <MaskedTextField {...props} mask="__-_______" format={formatEIN} />;
}

export { MaskedTextField };
