import { Link as RemixLink } from '@remix-run/react';
// eslint-disable-next-line no-restricted-imports
import NextLink, { LinkProps } from 'next/link';
import React from 'react';
import { useInRouterContext } from 'react-router-dom';

import { useFlag } from '@headway/feature-flags/react';
import {
  isCareAppDestination,
  normalizeUrl,
} from '@headway/shared/utils/request';

import { useApplicationName } from '../contexts/ApplicationNameContext';

interface IProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  to?: string;
  href?: LinkProps['href'];
}

export const Link = React.forwardRef<HTMLAnchorElement, IProps>(
  ({ href, to, ...props }, ref) => {
    const isRemix = useInRouterContext();
    // Remix and Next use different props for the destination URL
    // so we need to normalize them here
    // @ts-expect-error
    let normalizedDestination = normalizeUrl(to ?? href);

    const appName = useApplicationName();
    const remixEnabledRoutes = useFlag('agoraRemixRoutes', []);

    // If we're in a Remix app we always use the Remix Link component but we
    // need to reload the document when navigating to a non-remix route.
    // Otherwise, we render either a Next.js Link or a standard anchor tag depending
    // on whether the route is enabled for Remix or not.  This is to ensure that
    // we are sending requests through our Express server when we need to and preserving
    // browser history.
    if (isRemix) {
      return (
        <RemixLink
          reloadDocument={!remixEnabledRoutes.includes(to ?? href)}
          // @ts-expect-error
          to={(href ?? to)!}
          ref={ref}
          {...props}
        />
      );
    } else if (remixEnabledRoutes.includes(to ?? href)) {
      if (typeof normalizedDestination !== 'string') {
        throw new Error('Link to object not implemented Link facade');
      }

      return <a href={normalizedDestination} ref={ref} {...props} />;
    }

    let shouldUseNextLink = false;

    if (appName === 'CARE' && isCareAppDestination(normalizedDestination!)) {
      shouldUseNextLink = true;
    } else if (
      appName === 'MARKETING' &&
      !isCareAppDestination(normalizedDestination!)
    ) {
      shouldUseNextLink = true;
    }

    if (shouldUseNextLink) {
      return (
        <NextLink href={(href ?? to)!} ref={ref} {...props}>
          {props.children}
        </NextLink>
      );
    }

    /** We should only hit this code if we are crosslinking between CARE and MARKETING */
    const destination = isCareAppDestination(normalizedDestination!)
      ? new URL(normalizedDestination, process.env.NEXT_PUBLIC_CARE_URL)
      : new URL(normalizedDestination, process.env.NEXT_PUBLIC_MARKETING_URL);

    return <a href={destination.toString()} ref={ref} {...props} />;
  }
);
