// eslint-disable-next-line lodash/import-scope
import type { Dictionary } from 'lodash';
import keyBy from 'lodash/keyBy';
import queryString from 'query-string';
import { useMemo } from 'react';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { FrontEndCarrierReadByUser } from '@headway/api/models/FrontEndCarrierReadByUser';
import { GeolocationRead } from '@headway/api/models/GeolocationRead';
import { ModalityRead } from '@headway/api/models/ModalityRead';
import { ProviderSearchFilters } from '@headway/api/models/ProviderSearchFilters';
import { ProviderSearchIndexLocationStrategy } from '@headway/api/models/ProviderSearchIndexLocationStrategy';
import { ProviderSearchIndexResultsFilters } from '@headway/api/models/ProviderSearchIndexResultsFilters';
import { ProviderSearchRead } from '@headway/api/models/ProviderSearchRead';
import { ProviderStateSearchIndexRecordRead } from '@headway/api/models/ProviderStateSearchIndexRecordRead';
import { SpecialtyRead } from '@headway/api/models/SpecialtyRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserSessionLocationPreference } from '@headway/api/models/UserSessionLocationPreference';
import { GeolocationApi } from '@headway/api/resources/GeolocationApi';
import { SearchIndexApi } from '@headway/api/resources/SearchIndexApi';
import { useQuery } from '@headway/shared/react-query';
import { isCarrierInHeadwayNetworkForState } from '@headway/shared/utils/carriers';
import { isFrontEndCarrierIdBcbs } from '@headway/shared/utils/insuranceUtils';
import {
  filtersToQueryParams,
  getCoordinatesFromCurrentFiltersAndGeolocationLadder,
  getDayOfWeekFromAvailabilityFilter,
  getProviderFunction,
  getTimeOfDayFromAvailabilityFilter,
} from '@headway/shared/utils/providerSearchFilters';

import { GEOLOCATION_LADDER_CACHE_KEY } from '../utils/cacheKeys';
import { getValidProviderLicenseTypes } from '../utils/providerSearch';
import { useRouter } from './useRouter';
import {
  useKnownFrontEndCarrierIds,
  useSearchableFrontEndCarriersQuery,
} from './useSearchableFrontEndCarriersQuery';

export const getCarrierIdIfInNetwork = (
  carrier: FrontEndCarrierRead | FrontEndCarrierReadByUser,
  options: {
    state?: UnitedStates;
    isBlueCard?: boolean;
  } = {}
): number | undefined => {
  const { state, isBlueCard } = options;
  if (!state) return;

  if (
    isCarrierInHeadwayNetworkForState(carrier, state) ||
    (isBlueCard && isFrontEndCarrierIdBcbs(carrier.id))
  ) {
    return carrier.id;
  }
};

export type ProvidersSearchState = {
  providers: ProviderSearchRead[] | ProviderStateSearchIndexRecordRead[];
  totalPages: number;
  totalProviderCount: number;
  queryId?: string;
  queryFilters?: ProviderSearchIndexResultsFilters;
  isFetching?: boolean;
  sessionId?: number;
};

interface Props {
  currentFilters: ProviderSearchFilters;
  providersPerPage: number;
  specialties?: SpecialtyRead[];
  modalities?: ModalityRead[];
  currentSessionId?: number;
  geolocationLadder?: GeolocationRead[];
}

export const useProvidersSearch = ({
  currentFilters,
  providersPerPage,
  specialties,
  currentSessionId,
}: Props): ProvidersSearchState => {
  const frontEndCarriersQuery = useSearchableFrontEndCarriersQuery();

  const carriersById:
    | Dictionary<FrontEndCarrierReadByUser | undefined>
    | Dictionary<FrontEndCarrierRead | undefined> = useMemo(
    () => keyBy(frontEndCarriersQuery.data, 'id'),
    [frontEndCarriersQuery.data]
  );
  const knownFrontEndCarrierIds = useKnownFrontEndCarrierIds();

  const router = useRouter();
  const state = router.query?.['state']?.[0];
  const extraLocationSlug = router.query?.['state']?.[1];

  const geolocationLadderCacheKey = [GEOLOCATION_LADDER_CACHE_KEY, state];
  if (extraLocationSlug) {
    geolocationLadderCacheKey.push(extraLocationSlug);
  }

  const geolocationLadder = useQuery(geolocationLadderCacheKey, () => {
    return GeolocationApi.getGeolocationLadder(
      undefined,
      state
        ? { state, extra_location_descriptor: extraLocationSlug }
        : undefined
    );
  });

  const { lat, lng, upperLat, upperLng, lowerLat, lowerLng } =
    getCoordinatesFromCurrentFiltersAndGeolocationLadder(
      currentFilters,
      geolocationLadder.data ?? []
    );

  const queryResults = useQuery(
    ['providers-algolia', currentFilters, providersPerPage, specialties],
    () => {
      if (!currentFilters.state || !specialties) {
        return null;
      }

      const carrierFromFilters = currentFilters.frontEndCarrierId
        ? carriersById[currentFilters.frontEndCarrierId]
        : undefined;

      const validProviderLicenseTypes =
        getValidProviderLicenseTypes(currentFilters);

      // clean up fields that are deprecated
      delete currentFilters.sessionLocation;
      return SearchIndexApi.searchProvidersIndex({
        ethnicities: currentFilters.ethnicities,
        front_end_carriers: carrierFromFilters
          ? getCarrierIdIfInNetwork(carrierFromFilters, {
              state: currentFilters.state,
              isBlueCard: currentFilters.isBlueCard,
            })
          : undefined,
        known_front_end_carrier_ids: knownFrontEndCarrierIds,
        gender: currentFilters.genders,
        hits_per_page: providersPerPage,
        in_person: currentFilters.medium?.includes(
          UserSessionLocationPreference.IN_PERSON
        ),
        is_blue_card: currentFilters.isBlueCard,
        languages: currentFilters.languages,
        location_preference: currentFilters.sessionLocation,
        lat: lat,
        lon: lng,
        lower_lat: lowerLat,
        lower_lon: lowerLng,
        modalities: currentFilters.selectedModalities,
        location_search_strategy: currentFilters.address
          ? ProviderSearchIndexLocationStrategy.NEARBY_FIRST_AND_REQUIRE_IN_GEOFENCE
          : undefined,
        page:
          currentFilters?.currentPage && currentFilters.currentPage > 1
            ? currentFilters.currentPage - 1
            : 0,
        patient_age: currentFilters.patientAge,
        patient_age_groups: currentFilters.forChild,
        phone_consult: currentFilters.phoneConsults,
        provider_function: getProviderFunction(currentFilters.typeOfCare),
        is_phypa_provider: currentFilters.isPhypaProvider,
        is_bti_provider: currentFilters.isBtiProvider,
        search_str: currentFilters.freeText,
        provider_age_group: currentFilters.providerAges,
        specialties: currentFilters.issues
          ?.map(
            (issue) =>
              specialties?.find((specialty) => specialty.key === issue)?.id
          )
          .filter(
            (specialtyId): specialtyId is number => specialtyId !== undefined
          ),
        // If we don't have a state we default to NY. User will be prompted to select their state.
        state: currentFilters.state,
        topics: currentFilters.topics,
        telehealth:
          currentFilters.medium?.includes(
            UserSessionLocationPreference.VIRTUAL
          ) ||
          currentFilters.medium?.includes(
            UserSessionLocationPreference.VIRTUAL_FOR_NOW
          ),
        upper_lat: upperLat,
        upper_lon: upperLng,
        day_of_week: getDayOfWeekFromAvailabilityFilter(
          currentFilters.availabilities ?? []
        ),
        time_of_day: getTimeOfDayFromAvailabilityFilter(
          currentFilters.availabilities ?? []
        ),
        provider_license_types: validProviderLicenseTypes,
      });
    },
    { keepPreviousData: true, enabled: !frontEndCarriersQuery.isLoading }
  );

  return {
    providers: queryResults.data?.providers ?? [],
    totalPages: queryResults.data?.totalPages ?? 1,
    totalProviderCount: queryResults.data?.totalProviders ?? 0,
    queryId: queryResults.data?.queryId,
    sessionId: queryResults.data?.sessionId,
    queryFilters: queryResults.data?.queryFilters,
    // you need to add this `|| !algoriaQuery.data` to take into account the initial search
    // or else `isFetching` will be `true` -> `false` -> `true` and it then creates a UI glitch
    isFetching: queryResults.isFetching || !queryResults.data,
  };
};

export const getProvidersSearchPath = ({
  marketSlug,
  filters = {},
}: {
  marketSlug?: string;
  filters?: Partial<ProviderSearchFilters>;
} = {}): string => {
  const basePath = marketSlug ? `/search/${marketSlug}` : '/search';

  const [params] = filtersToQueryParams(filters);

  return `${basePath}?${queryString.stringify(params)}`;
};
