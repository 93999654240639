/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */import {EnumMetadata} from '../metadata'

    /**
    * An enumeration.
    */
export enum UnitedStatesAbbreviations {
    AK = 'AK',
    AL = 'AL',
    AR = 'AR',
    AZ = 'AZ',
    CA = 'CA',
    CO = 'CO',
    CT = 'CT',
    DC = 'DC',
    DE = 'DE',
    FL = 'FL',
    GA = 'GA',
    HI = 'HI',
    IA = 'IA',
    ID = 'ID',
    IL = 'IL',
    IN = 'IN',
    KS = 'KS',
    KY = 'KY',
    LA = 'LA',
    MA = 'MA',
    MD = 'MD',
    ME = 'ME',
    MI = 'MI',
    MN = 'MN',
    MO = 'MO',
    MS = 'MS',
    MT = 'MT',
    NC = 'NC',
    ND = 'ND',
    NE = 'NE',
    NH = 'NH',
    NJ = 'NJ',
    NM = 'NM',
    NV = 'NV',
    NY = 'NY',
    OH = 'OH',
    OK = 'OK',
    OR = 'OR',
    PA = 'PA',
    PR = 'PR',
    RI = 'RI',
    SC = 'SC',
    SD = 'SD',
    TN = 'TN',
    TX = 'TX',
    UT = 'UT',
    VA = 'VA',
    VI = 'VI',
    VT = 'VT',
    WA = 'WA',
    WI = 'WI',
    WV = 'WV',
    WY = 'WY'
}


export const UnitedStatesAbbreviationsMetadata: EnumMetadata<UnitedStatesAbbreviations> = {
    name: 'UnitedStatesAbbreviations',
    values: {
            AK: 'AK',
            AL: 'AL',
            AR: 'AR',
            AZ: 'AZ',
            CA: 'CA',
            CO: 'CO',
            CT: 'CT',
            DC: 'DC',
            DE: 'DE',
            FL: 'FL',
            GA: 'GA',
            HI: 'HI',
            IA: 'IA',
            ID: 'ID',
            IL: 'IL',
            IN: 'IN',
            KS: 'KS',
            KY: 'KY',
            LA: 'LA',
            MA: 'MA',
            MD: 'MD',
            ME: 'ME',
            MI: 'MI',
            MN: 'MN',
            MO: 'MO',
            MS: 'MS',
            MT: 'MT',
            NC: 'NC',
            ND: 'ND',
            NE: 'NE',
            NH: 'NH',
            NJ: 'NJ',
            NM: 'NM',
            NV: 'NV',
            NY: 'NY',
            OH: 'OH',
            OK: 'OK',
            OR: 'OR',
            PA: 'PA',
            PR: 'PR',
            RI: 'RI',
            SC: 'SC',
            SD: 'SD',
            TN: 'TN',
            TX: 'TX',
            UT: 'UT',
            VA: 'VA',
            VI: 'VI',
            VT: 'VT',
            WA: 'WA',
            WI: 'WI',
            WV: 'WV',
            WY: 'WY'
    }
}
