/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { BookProviderEventRequest } from '../models/BookProviderEventRequest';
import { ConcreteProviderEventRead } from '../models/ConcreteProviderEventRead';
import { GetPaginatedProviderEventForCalendarResponse } from '../models/GetPaginatedProviderEventForCalendarResponse';
import { GetPaginatedProviderEventReadResponse } from '../models/GetPaginatedProviderEventReadResponse';
import { GetSessionsByProviderSessionSummary } from '../models/GetSessionsByProviderSessionSummary';
import { HTTPValidationError } from '../models/HTTPValidationError';
import { InvoiceType } from '../models/InvoiceType';
import { PatientAppointmentReadinessCutoffDateResponseBody } from '../models/PatientAppointmentReadinessCutoffDateResponseBody';
import { ProviderAppointmentStatus } from '../models/ProviderAppointmentStatus';
import { ProviderEventChannel } from '../models/ProviderEventChannel';
import { ProviderEventCreate } from '../models/ProviderEventCreate';
import { ProviderEventIdInfo } from '../models/ProviderEventIdInfo';
import { ProviderEventRead } from '../models/ProviderEventRead';
import { ProviderEventRecurrenceUpdateResponse } from '../models/ProviderEventRecurrenceUpdateResponse';
import { ProviderEventType } from '../models/ProviderEventType';
import { ProviderEventUpdate } from '../models/ProviderEventUpdate';
import { ProviderPaymentStatus } from '../models/ProviderPaymentStatus';
import { SessionDetailsConfirmabilityResponse } from '../models/SessionDetailsConfirmabilityResponse';
import { SessionDetailsEditabilityStatus } from '../models/SessionDetailsEditabilityStatus';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ProviderEventApiResource {

    /**
     * Book Event
     * 
     * @param bookProviderEventRequest 
     
     */
    public bookEvent(body: BookProviderEventRequest, axiosConfig?: AxiosRequestConfig): Promise<ConcreteProviderEventRead> {
        const apiRoute = '/book-provider-event';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Create Event
     * 
     * @param providerEventCreate 
     
     */
    public createEvent(body: ProviderEventCreate, axiosConfig?: AxiosRequestConfig): Promise<ConcreteProviderEventRead> {
        const apiRoute = '/provider-event';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Delete Event
     * 
     * @param eventIdOrVirtualEventId 
     
     */
    public deleteEvent(eventIdOrVirtualEventId: any, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/provider-event/{event_id_or_virtual_event_id}';
        const reqPath = apiRoute
                    .replace('{' + 'event_id_or_virtual_event_id}', String(eventIdOrVirtualEventId));
        const reqConfig = {
            ...axiosConfig,
            method: 'DELETE' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Delete Recurring Instance And All Following
     * 
     * @param virtualId 
     
     */
    public deleteRecurringInstanceAndAllFollowing(virtualId: string, axiosConfig?: AxiosRequestConfig): Promise<ConcreteProviderEventRead> {
        const apiRoute = '/provider-event/{virtual_id}/this-and-following';
        const reqPath = apiRoute
                    .replace('{' + 'virtual_id}', String(virtualId));
        const reqConfig = {
            ...axiosConfig,
            method: 'DELETE' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Export As Pdf
     * 
     * @param invoiceType 
     * @param eventId 
     * @param startDate 
     * @param endDate 
     * @param patientUserId 
     
     */
    public exportAsPdf(query?: { event_id?: number, start_date?: string, end_date?: string, patient_user_id?: number, invoice_type: InvoiceType }, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-event/export/pdf';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Appointments With Held Charges
     * 
     * @param userId 
     
     */
    public getAppointmentsWithHeldCharges(userId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<ConcreteProviderEventRead>> {
        const apiRoute = '/provider-event/appointments-with-held-charges/{user_id}';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Event By Id
     * 
     * @param eventIdOrVirtualEventId 
     * @param expandEstimatedPrices 
     * @param expandBaseRecurringEvents 
     
     */
    public getEventById(eventIdOrVirtualEventId: any, query?: { expand_estimated_prices?: boolean, expand_base_recurring_events?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<ProviderEventRead> {
        const apiRoute = '/provider-event/{event_id_or_virtual_event_id}';
        const reqPath = apiRoute
                    .replace('{' + 'event_id_or_virtual_event_id}', String(eventIdOrVirtualEventId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Events
     * 
     * @param providerId 
     * @param patientUserId 
     * @param appointmentStatuses 
     * @param channels 
     * @param dateRangeStart 
     * @param dateRangeEnd 
     * @param eventTypes 
     * @param expandEstimatedPrices 
     * @param providerAppointmentIds 
     * @param providerPaymentDateStart 
     * @param providerPaymentDateEnd 
     * @param providerPaymentStatusEnums 
     * @param orderBy 
     * @param order 
     * @param offset 
     * @param limit 
     * @param expand 
     * @param useMinimalAppointmentFields 
     * @param expandTelehealthJoinInfo 
     * @param source 
     
     */
    public getEvents(query?: { provider_id?: number, patient_user_id?: number, appointment_statuses?: Array<ProviderAppointmentStatus>, channels?: Array<ProviderEventChannel>, date_range_start?: string, date_range_end?: string, event_types?: Array<ProviderEventType>, expand_estimated_prices?: boolean, provider_appointment_ids?: Array<number>, provider_payment_date_start?: string, provider_payment_date_end?: string, provider_payment_status_enums?: Array<ProviderPaymentStatus>, order_by?: string, order?: string, offset?: number, limit?: number, expand?: boolean, use_minimal_appointment_fields?: boolean, expand_telehealth_join_info?: boolean, source?: string }, axiosConfig?: AxiosRequestConfig): Promise<GetPaginatedProviderEventReadResponse> {
        const apiRoute = '/provider-event';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Events For Calendar
     * 
     * @param providerId 
     * @param dateRangeStart 
     * @param dateRangeEnd 
     * @param patientUserId 
     * @param appointmentStatuses 
     * @param channels 
     * @param eventTypes 
     * @param providerAppointmentIds 
     * @param orderBy 
     * @param order 
     * @param offset 
     * @param limit 
     
     */
    public getEventsForCalendar(providerId: number, query?: { date_range_start: string, date_range_end: string, patient_user_id?: number, appointment_statuses?: Array<ProviderAppointmentStatus>, channels?: Array<ProviderEventChannel>, event_types?: Array<ProviderEventType>, provider_appointment_ids?: Array<number>, order_by?: string, order?: string, offset?: number, limit?: number }, axiosConfig?: AxiosRequestConfig): Promise<GetPaginatedProviderEventForCalendarResponse> {
        const apiRoute = '/provider/{provider_id}/provider-events-for-calendar';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Patient Appointment Readiness Cutoff Date
     * 
     * @param eventIdOrVirtualEventId 
     
     */
    public getPatientAppointmentReadinessCutoffDate(eventIdOrVirtualEventId: any, axiosConfig?: AxiosRequestConfig): Promise<PatientAppointmentReadinessCutoffDateResponseBody> {
        const apiRoute = '/provider-event/{event_id_or_virtual_event_id}/patient-appointment-readiness-cutoff-date';
        const reqPath = apiRoute
                    .replace('{' + 'event_id_or_virtual_event_id}', String(eventIdOrVirtualEventId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Confirmable Sessions
     * 
     * @param providerId 
     
     */
    public getProviderConfirmableSessions(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<GetSessionsByProviderSessionSummary>> {
        const apiRoute = '/provider/{provider_id}/get-confirmable-provider-events';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider License Confirmability
     * 
     * @param providerId 
     * @param appointmentDatetime 
     * @param isTelehealth 
     * @param providerAddressId 
     * @param patientAddressId 
     
     */
    public getProviderLicenseConfirmability(query?: { provider_id: number, appointment_datetime: string, is_telehealth: boolean, provider_address_id?: number, patient_address_id?: number }, axiosConfig?: AxiosRequestConfig): Promise<SessionDetailsConfirmabilityResponse> {
        const apiRoute = '/provider-event/session-details-confirmability/provider-license';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Recoup Events
     * 
     * @param userId 
     * @param excludeSettled 
     
     */
    public getRecoupEvents(userId: number, query?: { exclude_settled?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<Array<ConcreteProviderEventRead>> {
        const apiRoute = '/provider-event/recoups/{user_id}';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Session Details Confirmabilities For Events
     * 
     * @param providerEventIdInfo 
     
     */
    public getSessionDetailsConfirmabilitiesForEvents(body: Array<ProviderEventIdInfo>, axiosConfig?: AxiosRequestConfig): Promise<{ [key: string]: SessionDetailsConfirmabilityResponse; }> {
        const apiRoute = '/provider-event/session-details-confirmabilities';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Session Details Confirmability
     * 
     * @param virtualId 
     
     */
    public getSessionDetailsConfirmability(virtualId: string, axiosConfig?: AxiosRequestConfig): Promise<SessionDetailsConfirmabilityResponse> {
        const apiRoute = '/provider-event/{virtual_id}/session-details-confirmability';
        const reqPath = apiRoute
                    .replace('{' + 'virtual_id}', String(virtualId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Session Details Editability
     * 
     * @param virtualId 
     
     */
    public getSessionDetailsEditability(virtualId: string, axiosConfig?: AxiosRequestConfig): Promise<SessionDetailsEditabilityStatus> {
        const apiRoute = '/provider-event/{virtual_id}/session-details-editability';
        const reqPath = apiRoute
                    .replace('{' + 'virtual_id}', String(virtualId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Unconfirmed Appointment Counts For Group Practice
     * 
     * @param groupPracticeId 
     * @param startDate 
     * @param endDate 
     * @param timezoneStr 
     
     */
    public getUnconfirmedAppointmentCountsForGroupPractice(groupPracticeId: number, query?: { start_date: string, end_date: string, timezone_str: string }, axiosConfig?: AxiosRequestConfig): Promise<{ [key: string]: number; }> {
        const apiRoute = '/group-practice/{group_practice_id}/provider-event/unconfirmed-appointment-counts';
        const reqPath = apiRoute
                    .replace('{' + 'group_practice_id}', String(groupPracticeId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Event
     * 
     * @param eventIdOrVirtualId 
     * @param providerEventUpdate 
     * @param expandEstimatedPrices 
     
     */
    public updateEvent(eventIdOrVirtualId: any, body: ProviderEventUpdate, query?: { expand_estimated_prices?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<ConcreteProviderEventRead> {
        const apiRoute = '/provider-event/{event_id_or_virtual_id}';
        const reqPath = apiRoute
                    .replace('{' + 'event_id_or_virtual_id}', String(eventIdOrVirtualId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            params: query,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Recurring Instance And All Following
     * 
     * @param virtualId 
     * @param providerEventUpdate 
     
     */
    public updateRecurringInstanceAndAllFollowing(virtualId: string, body: ProviderEventUpdate, axiosConfig?: AxiosRequestConfig): Promise<ProviderEventRecurrenceUpdateResponse> {
        const apiRoute = '/provider-event/{virtual_id}/this-and-following';
        const reqPath = apiRoute
                    .replace('{' + 'virtual_id}', String(virtualId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Recurring Instance And End Recurrence
     * 
     * @param virtualId 
     * @param providerEventUpdate 
     
     */
    public updateRecurringInstanceAndEndRecurrence(virtualId: string, body: ProviderEventUpdate, axiosConfig?: AxiosRequestConfig): Promise<ProviderEventRecurrenceUpdateResponse> {
        const apiRoute = '/provider-event/{virtual_id}/update-and-end-recurrence';
        const reqPath = apiRoute
                    .replace('{' + 'virtual_id}', String(virtualId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ProviderEventApi = new ProviderEventApiResource();
