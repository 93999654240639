/**
 * Use with Yup.string().matches(YUP_PHONE_MATCH, { ...options }).
 * Allows initial "(" for phone mask
 */
export const YUP_PHONE_MATCH = /^$|(^\($|\(\d{3}\) \d{3}-\d{4})/;

export const YUP_EMAIL_MATCH =
  /^[a-zA-Z0-9.!#$%&‘*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const YUP_INITIALS_MATCH = /^[a-zA-Z]{2}$/;

export const YUP_PHONE_ERROR_MESSAGE =
  'Make sure the phone number follows this format: (###) ###-####.';

export const YUP_NO_AT_SIGN_MATCH = /^(?:(?!@).)*$/;
export const YUP_NO_AT_SIGN_MESSAGE = 'Please enter a name without @';

export const YUP_NAME_NO_SPECIAL_CHARACTERS_MATCH = /^[a-zA-Z'.\s-]+$/;

/**
 * Standardized phone format used for frontend input forms when
 * creating users or updating user info.
 */
export const PHONE_MASK = '(999) 999-9999';

export const YUP_NPI_MATCH = /^$|(^\d{10}$)/;
export const YUP_NPI_ERROR_MESSAGE = 'NPI must be 10 digits.';
